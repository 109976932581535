window.addEventListener('scroll', function(event) {

    var scroll = $(window).scrollTop(),
        windowHeight = $(window).outerHeight(),
        documentHeight = $(document).outerHeight(),
        viewPortBreak = scroll+windowHeight;

    if ( scroll > 0 && !$('.Navigation .is-open').length ) {
        $('body').addClass('is-navMinified');
    } else {
        $('body').removeClass('is-navMinified');
    }

    if ( viewPortBreak >= documentHeight ) {
        $('body').addClass('is-scrolledBottom');
    } else {
        $('body').removeClass('is-scrolledBottom');
    }

}, { passive: true });
var event = new Event('scroll');
window.dispatchEvent(event);
