function checkVideoSize() {
    if ( $('.js-video').length ) {
        if ( $('.js-video').parent('.Video-single').hasClass('is-checked') ) {
            setVideoSize();
        } else {
            var vid = $('.js-video');
            vid.get(0).addEventListener("loadedmetadata", function (e) {
                $('.js-video').parent('.Video-single').attr('data-width', this.videoWidth);
                $('.js-video').parent('.Video-single').attr('data-height', this.videoHeight);
                $('.js-video').parent('.Video-single').addClass('is-checked');
                setVideoSize();
            }, false );
        }
    }
}
checkVideoSize();

function setVideoSize() {
    var videoContainerWidth = Math.round($('.Video-single').width()),
        videoContainerHeight = Math.round($('.Video-single').height()),
        areaRatio = videoContainerWidth/videoContainerHeight,
        videoWidth = $('.js-video').parent('.Video-single').attr('data-width'),
        videoHeight = $('.js-video').parent('.Video-single').attr('data-height'),
        videoRatio = videoWidth/videoHeight;
    if ( areaRatio > videoRatio ) {
        $('.js-video').parent('.Video-single').addClass('is-notFullWidth');
    } else {
        $('.js-video').parent('.Video-single.is-notFullWidth').removeClass('is-notFullWidth');
    }
}

$(window).on('resize',function(e){
    setVideoSize();
});

function setVideoActions() {
    $('.js-video').on('click touchup',function(){
        var video = $(this).get(0);
        video.paused ? video.play() : video.pause();
    });
}
setVideoActions();

if ( $('.Video-single').length && localStorage.video_index != '' ) {
    $('.Video-index').text(localStorage.video_index);
}

function videoProgress() {
    var $video = $('.js-video'),
        current_position = $video.get(0).currentTime,
        max_duration = $video.get(0).duration,
        percentage = 100 * current_position / max_duration;

    if(percentage > 100) {
        percentage = 100;
    }
    if(percentage < 0) {
        percentage = 0;
    }

    $('.js-progressBar').css('width', percentage+'%');
}

function updateVideoProgress(event) {
    var $progress_bar = $(event.target),
        $video = $('.js-video').get(0),
        max_duration = $video.duration,
        x = event.pageX,
        position = (x - $progress_bar.offset().left),
        percentage = (100 * position / $progress_bar.width());

    if(percentage > 100) {
        percentage = 100;
    }
    if(percentage < 0) {
        percentage = 0;
    }

    $('.js-progressBar').css('width', percentage+'%');
    $video.currentTime = (max_duration * percentage / 100);
}

if ( $('.js-video').length ) {

    $(window).on('scroll',function(){
        if ( !$('body').hasClass('is-navMinified') && !$('body').hasClass('is-fullscreen') ) {
            var video = $('.js-video').get(0);
            video.pause();
        }
    });

    $('.js-video').get(0).addEventListener("play", function() {
        $('body').addClass('is-playing');
    });

    $('.js-video').get(0).addEventListener("pause", function() {
        $('body').removeClass('is-playing');
    });

    $('.js-video').on('timeupdate', function(event) {
        videoProgress();
    });

    var draggingVideo;

    $('body').on('mousedown', '.js-progress', function(event) {
        draggingVideo = true;
        updateVideoProgress(event);
    });

    $(document).mouseup(function(event) {
        if( draggingVideo ) {
            draggingVideo = false;
            updateVideoProgress(event);
        }
    });

    $(document).mousemove(function(event) {
        if( draggingVideo ) {
            updateVideoProgress(event);
        }
    });

}

if ( !is_touch_device() ) {

    function loadStills(stills) {
        stills.find('.js-still').each(function(){
            var still = $(this),
                srcset = still.attr('data-org-srcset');
            still.attr('srcset', srcset)
            .on('load', function() {
                if (this.complete || typeof this.naturalWidth != "undefined" || this.naturalWidth != 0) {
                    still.addClass('is-loaded');
                    if ( !stills.find('.js-still.is-active').length ) {
                        still.addClass('is-active');
                    }
                }
            });
        });
    }

    var previewTimeout,
        previewLoading,
        previewInterval,
        previewDelay = false,
        previewDelayTimeout;
    $('.js-preview').on('mouseenter',function(){
        var preview = $(this),
            stills = preview.find('.js-stills');
        if ( !previewDelay && stills ) {
            previewDelay = true;
            loadStills(stills);
            previewDelayTimeout = setTimeout(function(){
                preview.addClass('is-hover');
                previewLoading = true;
                previewTimeout = setTimeout(function(){
                    previewLoading = false;
                    stills.addClass('is-interval');
                    previewInterval = setInterval(function(){
                        if ( stills.find('.is-active').nextAll('.js-still.is-loaded:first').length ) {
                            stills.find('.is-active').nextAll('.js-still.is-loaded:first').addClass('is-active').siblings('.js-still').removeClass('is-active');
                        } else {
                            stills.find('.js-still.is-loaded:first').addClass('is-active').siblings('.js-still').removeClass('is-active');
                        }
                    }, 300);
                    $('.js-icon').addClass('is-play');
                }, 60);
            }, 1000);
        }
    }).on('mouseleave',function(){
        var preview = $(this),
            stills = preview.find('.js-stills');
        preview.removeClass('is-hover');
        if ( previewDelay ) {
            clearTimeout(previewDelayTimeout);
            previewDelay = false;
        }
        if ( previewLoading ) {
            clearTimeout(previewTimeout);
            previewLoading = false;
        } else if ( stills.hasClass('is-interval') ) {
            clearInterval(previewInterval);
            stills.removeClass('is-interval');
            if ( stills.find('.js-still:first').length ) {
                stills.find('.js-still:first').addClass('is-active').siblings('.js-still').removeClass('is-active');
            }
            $('.js-icon').removeClass('is-play');
        }
    });
}
