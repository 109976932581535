if ( !is_touch_device() && !$('.Video-single').length ) {

    var snowing = false,
        snowInterval,
        snowIntensity = 400,
        snowTimeoutSet,
        snowTimeout,
        snowDelay = 60000;

    window.runSnow = function() {
        snowing = true;
        $('body').addClass('is-snowing');
        snowInterval = setInterval(function(){
            $('.js-snow').append('<div class="Snow-flake"></div>');
        }, snowIntensity);
    }

    window.stopSnow = function() {
        if ( snowing ) {
            $('body').removeClass('is-snowing');
            $('.js-snow').empty();
            clearInterval(snowInterval);
        }
    }

    var setSnowTimeout = function() {
        snowTimeoutSet = true;
        snowTimeout = setTimeout(function(){
            snowTimeoutSet = false;
            runSnow();
        }, snowDelay);
    }
    // setSnowTimeout();

    $(document).mousemove(function(){
        if ( snowTimeoutSet ) {
            snowTimeoutSet = false;
            clearTimeout(snowTimeout);
        }
        if ( snowing ) {
            stopSnow();
        }
        setSnowTimeout();
    });

    $(window).scroll(function(){
        if ( snowTimeoutSet ) {
            snowTimeoutSet = false;
            clearTimeout(snowTimeout);
        }
        if ( snowing ) {
            stopSnow();
        }
        setSnowTimeout();
    });

}
