if ( $('.js-thumb').length ) {

    function scrollListener() {
        var scroll = $(window).scrollTop(),
            windowHeight = $(window).outerHeight(),
            viewPortBreak = scroll+windowHeight;

        $('.js-thumb').each(function(){
            var thumb = $(this),
                image = thumb.find('.js-image'),
                top = Math.floor(thumb.offset().top);

            if ( viewPortBreak > (top - windowHeight) ) {
                var srcset = image.attr('data-org-srcset');
                image.attr('srcset', srcset)
                .on('load', function() {
                    if (this.complete || typeof this.naturalWidth != "undefined" || this.naturalWidth != 0) {
                        thumb.addClass('is-loaded');
                    }
                });
            }
        });
    }

    window.addEventListener('scroll', scrollListener, { passive: true });

    $(window).on('load', function(){
        scrollListener();
    });

    if ( !is_touch_device() ) {

        $('.js-thumb').on('mouseenter',function(){
            var thumb = $(this),
                image = thumb.find('.js-color'),
                srcset = image.attr('data-org-srcset');
            if ( !image.hasClass('is-loaded') ) {
                image.attr('srcset', srcset)
                .on('load', function() {
                    if (this.complete || typeof this.naturalWidth != "undefined" || this.naturalWidth != 0) {
                        thumb.addClass('is-loaded');
                        image.addClass('is-loaded');
                    }
                });

            }
        });

    }

}
