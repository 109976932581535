function is_touch_device() {
    try {
        document.createEvent("TouchEvent");
        return true;
    } catch (e) {
        return false;
    }
}

$(window).on('load',function(){
    $('body').addClass('is-loaded');
});

$(window).on('beforeunload',function(e){
    $('body').removeClass('is-loaded');
});

$(".js-anchor").on("click", function() {

    if ( $('body').hasClass('is-fullscreen') ) {

        $('body').removeClass('is-fullscreen');
        var position = $("#video").offset().top;
        $("html, body").scrollTop(position);
        setTimeout(function(){
            var position = $("#video").offset().top;
            $("html, body").scrollTop(position);
        }, 400);

    } else if ( $('body').hasClass('is-playing') ) {

        $('body').addClass('is-fullscreen')

    } else if ( $('body').hasClass('is-scrolledBottom') ) {

        var video = $('.js-video').get(0);
        video.play();

    } else {

        var id = $(this).attr("href").substring(1);
        var position = $("#" + id).offset().top;

        $("html, body").animate( {
            scrollTop: position
        }, 400 );

    }

    return false;
});

$('.Video a').on('click',function(){
    var video = $(this).parents('.Video'),
        index = video.find('.Video-index').text();
    localStorage.video_index = index;
});
